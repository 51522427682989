import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-web-menu-bar',
  templateUrl: './web-menu-bar.component.html',
  styleUrls: ['./web-menu-bar.component.css']
})
export class WebMenuBarComponent implements OnInit {

  show = false;
  fullScreen = true;
  template = ``;

  constructor(private route: Router,private renderer: Renderer2) {
  
    this.route.events.subscribe((e) => {      
      
    });

   }

   ngOnInit(): void {
    // Check if the device is mobile or tablet
    if (this.isMobileOrTablet()) {
      this.togglemenu(); // Run togglemenu only for mobile or tablet
    }
    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    setTimeout(() => {
      this.show = false;
    }, 1000);
  }

  isMobileOrTablet(): boolean {
    const width = window.innerWidth;
    return width <= 1024; // You can adjust this threshold as needed
  }

  togglemenu() {
    const mobileMenu = document.getElementById('mobile-menu');
    if (mobileMenu) {
      const isVisible = mobileMenu.style.display !== 'none';
      this.renderer.setStyle(mobileMenu, 'display', isVisible ? 'none' : 'block');
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

}
