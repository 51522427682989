
<app-web-menu-bar></app-web-menu-bar>

<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <div class="main_title">
        <span>Contact Us</span>
    </div>
    
    <div class="row p-3">
        <div class="col-lg-6">
            <div>
                <img src="../../../assets/website/contact_us.jpg" style="display: block; margin: auto; width: 80%;" alt="img">
            </div>
        </div>
        <div class="col-lg-6" style="padding-top: 70px;">
            <p class="d-flex align-items-center mb-3">
                <img src="../../assets/img/common icons/to-do-list.png" style="width: 40px; height: 40px;" alt="Phone Icon" />
                <span class="ms-3">9999422119</span>
            </p>
            <p class="d-flex align-items-center mb-3">
                <img src="../../assets/img/common icons/clientuser.png" style="width: 40px; height: 40px;" alt="Email Icon" />
                <span class="ms-3"><a href="mailto:info@neodigit.in" class="text-decoration-none">info&#64;neodigit.in</a></span>
            </p>
            <p class="d-flex align-items-center mb-3">
                <img src="../../assets/img/common icons/product.png" style="width: 40px; height: 40px;" alt="Address Icon" />
                <span class="ms-3">Plot No-854, Sector-45, Faridabad, Haryana-121003</span>
            </p>
        </div>
    </div>    
    
    <!-- Optional: Add some CSS for customization -->
    <style>
        .f-contact {
            max-width: 500px;
            margin: auto;
        }
    
        .card {
            background-color: #f9f9f9;
            border-radius: 8px;
        }
    
        .card-header {
            background-color: #007bff;
            color: white;
        }
    
        .list-unstyled li {
            padding: 10px 0;
        }
    
        .list-unstyled li img {
            transition: transform 0.3s;
        }
    
        .list-unstyled li img:hover {
            transform: scale(1.1);
        }
    
        a {
            color: #007bff;
        }
    </style>    
        
</main>
<!-- main-area-end -->

<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->